import { useRouter } from 'next/router';
import React, { useEffect } from 'react'
import FullPageLoader from '../components/FullPageLoader';
import { useAuth } from '../src/AuthContext';

export default function Home() {
  const router = useRouter();
  const {
    user: { user, token },
  } = useAuth()

  useEffect(() => {
    if (!user?.id) {
      router.push('/login');
    } else if (user.id) {
      router.push("home");
    }
  }, [user, router]);

  return <FullPageLoader />
}
