import { Box } from '@chakra-ui/react';
import Image from 'next/image';
import React from 'react'
// import spinner from '../../assets/loader.gif'

const FullPageLoader = () => {
  return (
    <Box
      position={"fixed"}
      width={"100%"}
      height={"100%"}
      top={"0"}
      left={"0"}
      background={"#f8f8f8ad"}
      zIndex={"999"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box zIndex={1000}>
        <Image
          src={"/assets/loader.gif"}
          alt={`loading`}
          width={0}
          height={0}
          priority
          sizes="100vw"
          style={{ width: '100%', height: 'auto', }}
        />
      </Box>
    </Box>
  )
}

export default FullPageLoader;